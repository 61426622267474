<template>
    <div v-if="directory">
        <title-bar
            submitBtn
            secondaryBtn
            :submitBtnTitle="$t('forms.addDirectory')"
            :secondaryBtnTitle="$t('forms.addDocument')"
            :title="$t('navigations.documents')"
            @submitPressed="submitPressed"
            @secondaryPressed="secondaryPressed"
        />
        <b-card
            :header="directory.name"
            header-tag="strong"
        >
            <directory-list :directory="directory" :documents="this.documents" :directories="this.directories"
                            :action="action"
                            @deleteDirectory="deleteDirectory"
                            @deleteDocument="deleteDocument"
                            @clearAction="clearAction"
                            @clearActionOnly="clearActionOnly"
                            @editDocument = "editDocument"
                            @editDirectory = "editDirectory"
            />
        </b-card>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import DirectoryList from "@/components/Files/DirectoryList";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
    name: "Documents",
    components: {
        TitleBar,
        DirectoryList,
    },
    mixins: [ResourceUtils],
    data() {
        return {
            directory: null,
            directories: [],
            documents: [],
            action: null
        };
    },
    computed: {},
    watch: {
        '$route.params.id': {
            handler: function () {
                if (this.$route.params.id) {
                    this.loadDirectories()
                    this.loadDocuments()
                }
            },
            deep: true,
            immediate: true
        },
    },
    methods: {
        loadDirectories() {
            this.$Directories.getResource({id: this.$route.params.id}, "directory_list").then((response) => {
                this.directory = response.data;
            });
            this.$Directories.getCollection({
                params: {
                    'parent': this.$route.params.id,
                    'order[position]': 'ASC',
                    'order[name]': 'ASC',
                    'pagination': false
                },
            }, "directory_list").then((response) => {
                this.directories = response.data["hydra:member"];
            });
        },
        loadDocuments() {
            this.$Documents.getCollection({
                params: {
                    'directory': this.$route.params.id,
                    'order[position]': 'ASC',
                    'order[name]': 'ASC',
                    'pagination': false
                }
            }).then((response) => {
                this.documents = response.data["hydra:member"];
            });
        },
        deleteDirectory(url) {
            this.deleteByUrl(this.$Files, url, this.$t("messages.directoryDeleted"), null, this.loadDirectories, null);
        },
        deleteDocument(url) {
            this.deleteByUrl(this.$Files, url, this.$t("messages.documentDeleted"), null, this.loadDocuments, null);
        },
        editDocument() {
            this.action = 'editDocument'
        },
        editDirectory() {
            this.action = 'editDirectory'
        },
        submitPressed() {
            this.action = 'addDirectory'
        },
        secondaryPressed() {
            this.action = 'addDocument'
        },
        clearAction() {
            this.loadDirectories()
            this.loadDocuments()
            this.action = null
        },
        clearActionOnly() {
            this.action = null
        }
    },
};
</script>
